/* eslint-disable react/prop-types */
import React from 'react';

const ConfigContext = React.createContext({});

export const ConfigContextProvider = ({config, children}) => {
  const nonce = '8d3c32adfa';
  return (
    <ConfigContext.Provider value={{nonce, config}}>
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfigContext = () => React.useContext(ConfigContext);
