const translations = {
  es: {
    translation: {
      siteMetadata: {
        title: 'Consigueme Un Prestamo',
        titleTemplate: 'Camino Financial',
      },
      gml10: {
        topNav: {
          link1: {
            label: 'Apoyo COVID-19',
            url: `${process.env.GATSBY_CAMINO_URL}/es/covid-19/`,
          },
          link1nav: {
            link1: {
              label: 'SBA Programa Protección de Nómina (PPP)',
              url: `${process.env.GATSBY_CAMINO_URL}/es/el-programa-de-la-sba-de-proteccion-a-las-nominas-ppp-ayuda-covid-19/`,
            },
            link2: {
              label: 'Préstamos por Daños Económicos por Desastres (EIDL)',
              url: `${process.env.GATSBY_CAMINO_URL}/es/eidl-los-prestamos-de-danos-economicos-por-desastres-de-la-sba-ayuda-covid-19/`,
            },
            link3: {
              label: 'Más apoyo COVID-19',
              url: `${process.env.GATSBY_CAMINO_URL}/es/covid-19/`,
            },
          },
          link2: {
            label: 'Préstamos de Negocios',
            url: `${process.env.GATSBY_CAMINO_URL}/es/prestamos-para-pequenas-empresas/`,
          },
          link3: {
            label: 'Recursos',
            url: `${process.env.GATSBY_CAMINO_URL}/es/blog/`,
          },
          link3nav: {
            link1: {
              label: 'Financiamiento de Negocios',
              url: `${process.env.GATSBY_CAMINO_URL}/es/category/financiamiento-de-negocios/`,
            },
            link2: {
              label: 'Contabilidad y Flujo de Caja',
              url: `${process.env.GATSBY_CAMINO_URL}/es/category/contabilidad-y-flujo-de-caja/`,
            },
            link3: {
              label: 'Comenzar un Negocio',
              url: `${process.env.GATSBY_CAMINO_URL}/es/category/comenzar-un-negocio/`,
            },
            link4: {
              label: 'Marketing & Ventas',
              url: `${process.env.GATSBY_CAMINO_URL}/es/category/marketing-ventas/`,
            },
            link5: {
              label: 'Administración de Empresas',
              url: `${process.env.GATSBY_CAMINO_URL}/es/category/administracion-de-empresas/`,
            },
            link6: {
              label: 'Tecnología y Operaciones',
              url: `${process.env.GATSBY_CAMINO_URL}/es/category/tecnologia-y-operaciones/`,
            },
            link7: {
              label: 'Noticias en La Comunidad',
              url: `${process.env.GATSBY_CAMINO_URL}/es/category/noticias-en-la-comunidad/`,
            },
            link8: {
              label: 'Encuesta Pequeños Negocios Latino',
              url: `${process.env.GATSBY_CAMINO_URL}/es/encuesta-pequenos-negocios-latino/`,
            },
          },
          link4: {
            label: 'Historias de Éxito',
            url: `${process.env.GATSBY_CAMINO_URL}/es/success-stories/`,
          },
          link5: {
            label: 'Quiénes Somos',
            url: `${process.env.GATSBY_CAMINO_URL}/es/sobre-nosotros/`,
          },
          link6: {
            label: 'INICIAR SESIÓN',
            url: `${process.env.GATSBY_MY_CAMINO_URL}/signin`,
          },
          link7: {
            label: 'APLICAR HOY',
            url: ``,
          },
          link8: {label: 'ENGLISH', url: 'http:'},
        },
        hero: {
          title1: 'Sí existe el préstamo',
          title2: 'perfecto',
          subtitle:
            'Abrimos las puertas a empresas con sólo ITIN y sin historial de crédito.',
          media_presence: 'LO QUE SE HABLA DE CAMINO FINANCIAL',
        },
        reviews: {
          title: 'Nuestro excelente servicio nos respalda',
        },
        calculator: {
          title: 'Calcula tus pagos',
          loan_amount: 'CAPITAL DESEADO',
          payment_term: 'NÚMERO DE CUOTAS',
          monthly_interest_rate: 'INTERÉS MENSUAL',
          monthly: 'MENSUALES',
          months: 'MESES',
          total_cost_of_loan: 'COSTO TOTAL DEL PRÉSTAMO',
          funding_fee: 'CARGO POR TRANSFERENCIA',
          documentation_fee: 'CARGO POR PAPELEO',
          closing_fee: 'CARGO POR CIERRE',
          total_interest_paid: 'TOTAL INTERESES A PAGAR',
          see_more: 'VER MÁS INFO',
          see_less: 'VER MENOS INFO',
          check_if_you_qualify: 'DESCUBRE SI CALIFICAS',
        },
        stats: {
          title1: 'Marcamos',
          title2: 'la diferencia',
          title3: 'con',
          number1: '$88M',
          description1: 'invertidos en empresas rechazadas por bancos',
          number2: '4,500',
          description2: 'préstamos (50% de ellos a minorías)',
          number3: ' $55M',
          description3: 'más ganancias para nuestros clientes',
          number4: ' $11M',
          description4:
            'ahorrados en intereses y comisiones vs otros prestamistas',
        },
        feats: {
          title1: 'Somos diferentes a los bancos,',
          title2: 'nosotros aceptamos:',
          feature1: 'Inmigrantes indocumentados',
          feature2: 'Personas sin historial crediticio',
          feature3: 'Empresas con sólo un empleado',
          feature4: 'Propietarios únicos',
          feature5: 'Negocios informales',
        },
        form: {
          firstnameError: 'Nombre es requerido',
          lastnameError: 'Apellido es requerido',
          phoneError: 'Número de Teléfono es requerido',
          phoneFormatError: 'Número de Teléfono no es valido',
          emailError: 'Email es requerido',
          emailFormatError: 'Email no es valido',
          loantypeError: 'Tipo de Préstamo es requerido',
          consentError: 'Consentimiento es requerido',
          firstname: 'Nombre',
          lastname: 'Apellido',
          phone: 'Número de Teléfono',
          email: 'Email',
          loantype: 'Tipo de Préstamo',
          business: 'Préstamo Empresarial',
          personal: 'Préstamo Personal',
          time: 'Tiempo en Operación',
          timeOpt1: 'Menos de 9 meses',
          timeOpt2: '9 - 12 Meses',
          timeOpt3: '1 - 2 Años',
          timeOpt4: '2 - 3 Años',
          timeOpt5: '3 - 4 Años',
          timeOpt6: '4 Años o Más',
          start_app: 'APLICA HOY',
          consent1:
            'Da su consentimiento para recibir emails y mensajes de texto de Camino Financial para saber más de sus productos/servicios (hasta 4 mensajes/semana, pueden aplicar cargos de su proveedor de servicio). Tiene la opción de no dar su consentimiento llamando al ',
          consent2: '(800) 852-0655',
          consent3: ' También acepta los  ',
          consent4: ' Términos y Condiciones ',
          consent5: ' y la  ',
          consent6: ' Política de Privacidad ',
          consent7: '  de Camino Financial.',
        },

        requirements: {
          title1: 'Conoce nuestros',
          title2: 'requisitos mínimos',
          title3: 'para aplicar',
          subtitle1: 'ITIN o SSN',
          description1: 'Debes tener un',
          subtitle2: 'de $30,000 o más',
          description2: 'Ingresos Anuales',
          subtitle3: 'más de 9 meses',
          description3: 'Antigüedad de Negocio',
        },
        ctaBox: {
          title1: 'Financia tu negocio ahora',
          description1:
            'Estamos dedicados a ayudar a las empresas minoritarias a tener éxito. Creemos que el acceso al capital es uno de los mayores desafíos para las empresas minoritarias.',
          btnText: 'APLICA HOY',
        },

        comparisonTable: {
          title1: 'El mejor crédito',
          title2: 'para negocios como el tuyo',
          rowHeader1: '¿Acepta ITIN?',
          rowHeader2: 'Acepta sin historial crediticio',
          rowHeader3: 'Interés anual',
          colHeader1: 'Camino Financial',
          colHeader2: 'Adelantos de Efectivo',
          colHeader3: 'Líneas de Crédito',
          valC1R3: '12-25%',
          valC2R2: 'Depende del prestamista',
          valC2R3: '40-150%',
          valC3R3: '7-25%',
        },
        splitSection: {
          title: 'Califica en 5 minutos',
        },

        footer: {
          title1: '¡CONÉCTATE!',
          description1:
            'Únete a una comunidad de más de de 40,000 personas que reciben consejos dos veces a la semana para hacer crecer su negocio.',
          signupPlaceholder: 'Introduce tu correo electrónico',
          signup: 'REGISTRARSE',
          title2: 'UNA COMPAÑÍA CON UNA MISIÓN',
          description2:
            'La misión de Camino Financial es favorecer el crecimiento económico en comunidades de ingresos bajos a moderados ayudando a las pequeñas empresas marginadas a crear nuevos trabajos y crecer de manera sostenible.',
          link2: {
            label: 'Lee nuestra historia',
            url: `${process.env.GATSBY_CAMINO_URL}/es/about-us/`,
          },
          title3: 'EXPLORAR',
          sitemap: {
            link1: {
              label: 'Historias de Éxito',
              url: `${process.env.GATSBY_CAMINO_URL}/success-stories/`,
            },
            link2: {
              label: 'Aplicar Hoy',
              url: `${process.env.GATSBY_CAMINO_URL}/get-me-a-loan10/`,
            },
            link3: {
              label: 'Préstamos de Negocios',
              url: `${process.env.GATSBY_CAMINO_URL}/small-business-loans/`,
            },
            link4: {
              label: 'Calculadora de Préstamos para Negocios',
              url: `${process.env.GATSBY_CAMINO_URL}/business-loan-calculator/`,
            },
            link5: {
              label: 'Mapa del Sitio',
              url: `${process.env.GATSBY_CAMINO_URL}/sitemap/`,
            },
            link6: {
              label: 'Seguridad',
              url: `${process.env.GATSBY_CAMINO_URL}/security/`,
            },
            link7: {label: '', url: ''},
            link8: {
              label: 'Iniciar Sesión',
              url: `${process.env.GATSBY_CAMINO_URL}/signin`,
            },
            link9: {
              label: 'Opiniones de Camino',
              url: `${process.env.GATSBY_CAMINO_URL}/es/reviews/`,
            },
            link10: {
              label: 'Quiénes Somos',
              url: `${process.env.GATSBY_CAMINO_URL}/es/sobre-nosotros/`,
            },
            link11: {
              label: 'Contactanos',
              url: `${process.env.GATSBY_CAMINO_URL}/es/contactanos/`,
            },
            link12: {
              label: 'Recursos',
              url: `${process.env.GATSBY_CAMINO_URL}/es/recursos/`,
            },
            link13: {
              label: 'Carreras',
              url: 'https://www.linkedin.com/company/camino-financial/jobs',
            },
            link14: {
              label: 'Blog de Tecnología',
              url: 'https://techblog.caminofinancial.com/',
            },
            link15: {
              label: 'Do Not Sell My Info',
              url:
                'https://privacyportal.onetrust.com/webform/e2cf87e2-04b0-4669-a94d-298d7fa99bbb/c315c398-738e-4cfd-8544-a7905247065a',
            },
          },
          bottomFotter: {
            link1: {
              label: 'Términos de uso',
              url: `${process.env.GATSBY_CAMINO_URL}/es/terms-of-use/`,
            },
            link2: {
              label: 'Política de Privacidad',
              url: `${process.env.GATSBY_CAMINO_URL}/es/privacy-policy/`,
            },
            link3: {
              label: 'CCPA',
              url: `${process.env.GATSBY_CAMINO_URL}/es/ccpa-policy/`,
            },
          },
          rightsReserved:
            '2021 por Camino Financial, Inc. Todos los derechos reservados. Camino Financial es un prestamista y corredor de finanzas con Îlicencia en California bajo su filial Salas & Company LLC.',
        },

        faq: {
          title: 'Preguntas frecuentes',
          question1: {
            question: '1. ¿Cómo puedo obtener un préstamo para negocios?',
            answer: '',
          },
          question2: {
            question: '2. ¿Cuáles son los requisitos?',
            answer: '',
          },
          question3: {
            question:
              '3. ¿Qué tipo de préstamo para negocio ofrece Camino Financial?',
            answer: '',
          },
          question4: {
            question:
              '4. ¿Debería aplicar para un préstamo de Camino Financial?',
            answer: '',
          },
          question5: {
            question:
              '5. ¿Puedo obtener un préstamo para negocio si solo tengo un ITIN?',
            answer: '',
          },
          question6: {
            question:
              '6. ¿Puedo solicitar un préstamo para negocio si no tengo historial crediticio?',
            answer: '',
          },
          question7: {
            question:
              '7. ¿La solicitud de Camino Financial afecta mi puntaje crediticio?',
            answer: '',
          },
          question8: {
            question:
              '8. ¿Necesito colateral para solicitar un préstamo de Camino Financial?',
            answer: '',
          },
          question9: {
            question: '9. ¿Cuáles son sus tasas de interés?',
            answer: '',
          },
          question10: {
            question: '10. ¿Es seguro el proceso de financiamiento?',
            answer: '',
          },
        },
      },
    },
  },
  en: {
    translation: {
      siteMetadata: {
        title: 'Get Me A Loan',
        titleTemplate: 'Camino Financial',
      },
      gml10: {
        topNav: {
          link1: {
            label: 'COVID-19 Relief',
            url: `${process.env.GATSBY_CAMINO_URL}/covid-19/`,
          },
          link1nav: {
            link1: {
              label: 'SBA Paycheck Protection Program (PPP)',
              url: `${process.env.GATSBY_CAMINO_URL}/the-sba-paycheck-protection-program-ppp-covid-19-relief/`,
            },
            link2: {
              label: 'Economic Injury Disaster Loans (EIDL)',
              url: `${process.env.GATSBY_CAMINO_URL}/the-sba-economic-injury-disaster-loans-eidl-covid-19-relief/`,
            },
            link3: {
              label: 'More COVID-19 Relief',
              url: `${process.env.GATSBY_CAMINO_URL}/covid-19/`,
            },
          },
          link2: {label: 'Business Loans', url: 'http:'},
          link3: {label: 'Business Resources', url: 'http:'},
          link3nav: {
            link1: {
              label: 'Business Funding',
              url: `${process.env.GATSBY_CAMINO_URL}/category/business-funding-2/`,
            },
            link2: {
              label: 'Accounting & Cash Flow',
              url: `${process.env.GATSBY_CAMINO_URL}/category/accounting-cash-flow/`,
            },
            link3: {
              label: 'Starting a Business',
              url: `${process.env.GATSBY_CAMINO_URL}/category/starting-a-business-2/`,
            },
            link4: {
              label: 'Marketing & Sales',
              url: `${process.env.GATSBY_CAMINO_URL}/category/marketing-sales/`,
            },
            link5: {
              label: 'Management & Lifestyle',
              url: `${process.env.GATSBY_CAMINO_URL}/category/management-lifestyle/`,
            },
            link6: {
              label: 'Technology & Operations',
              url: `${process.env.GATSBY_CAMINO_URL}/category/technology-and-operations/`,
            },
            link7: {
              label: 'Small Business News',
              url: `${process.env.GATSBY_CAMINO_URL}/category/small-business-news/`,
            },
            link8: {
              label: 'U.S. Latino Small Business Survey',
              url: `${process.env.GATSBY_CAMINO_URL}/latino-small-business-survey/`,
            },
          },
          link4: {label: 'Success Stories', url: 'http:'},
          link5: {label: 'About Us', url: 'http:'},
          link6: {
            label: 'SIGN IN',
            url: `${process.env.GATSBY_MY_CAMINO_URL}/signin`,
          },
          link7: {label: 'GET A QUOTE', url: 'http:'},
          link8: {label: 'ESPAÑOL', url: 'http:'},
        },
        hero: {
          title1: 'The perfect loan',
          title2: 'does exist',
          subtitle: 'We open doors to businesses, helping them to grow.',
          media_presence: 'MEDIA PRESENCE & REVIEWS',
        },
        reviews: {
          title:
            'We make sure to offer top-notch customer support to make our customers happy',
        },
        calculator: {
          title: 'Calculate your payments',
          loan_amount: 'LOAN AMOUNT',
          payment_term: 'PAYMENT TERM',
          monthly_interest_rate: 'MONTHLY INTEREST RATE',
          monthly: 'MONTHLY',
          months: 'MONTHS',
          total_cost_of_loan: 'TOTAL COST OF THE LOAN',
          funding_fee: 'FUNDING FEE',
          documentation_fee: 'DOCUMENTATION FEE',
          closing_fee: 'CLOSING FEE',
          total_interest_paid: 'TOTAL INTEREST PAID',
          see_more: 'VIEW MORE INFO',
          see_less: 'VIEW LESS INFO ',
          check_if_you_qualify: 'CHECK IF YOU QUALIFY',
        },
        stats: {
          title1: 'Camino Financial',
          title2: 'by the numbers',
          title3: '',
          number1: '$88M',
          description1: 'in loans to underserved markets',
          number2: '4,500',
          description2: 'loans (50% of them to minorities)',
          number3: ' $55M',
          description3: 'incremental revenue for our borrowers',
          number4: ' $11M',
          description4: 'saved in interest & fees vs other lenders',
        },
        feats: {
          title1: 'We’re not like most banks,',
          title2: 'we accept:',
          feature1: 'Owners with no credit history',
          feature2: 'Self-employed',
          feature3: 'Informal businesses',
          feature4: 'Cash-heavy businesses',
          feature5: '',
        },
        form: {
          firstnameError: 'First name is required',
          lastnameError: 'Last name is required',
          phoneError: 'Phone is required',
          phoneFormatError: 'Phone format is not valid',
          emailError: 'Email is required',
          emailFormatError: 'Email format is not valid',
          loantypeError: 'Loan type is required',
          consentError: 'Consent is required',
          firstname: 'First Name',
          lastname: 'Last Name',
          phone: 'Mobile Number',
          email: 'Email Address',
          loantype: 'Loan Type',
          business: 'Business Loan',
          personal: 'Personal Loan',
          time: 'Time in Business',
          timeOpt1: 'Under 9 months',
          timeOpt2: '9 - 12 Months',
          timeOpt3: '1 - 2 Years',
          timeOpt4: '2 - 3 Years',
          timeOpt5: '3 - 4 Years',
          timeOpt6: '4 Years or more',
          start_app: 'START APPLICATION',
          consent1:
            'You consent to receive emails & text messages from Camino Financial (up to 4 msgs/week, carrier fees may apply) to learn more about its products/services. You are not required to give consent and may call ',
          consent2: '(800) 852-0655',
          consent3:
            ' for more info. You also acknowledge you have read Camino Financial’s ',
          consent4: ' Terms & Conditions ',
          consent5: ' and ',
          consent6: ' Privacy Policy ',
          consent7: '.',
        },

        requirements: {
          title1: 'Discover our',
          title2: 'minimum requirements',
          title3: 'to apply',
          subtitle1: 'SSN or ITIN',
          description1: 'Business Owner’s',
          subtitle2: 'over $30,000',
          description2: 'Annual Revenue',
          subtitle3: 'Over 9 months',
          description3: 'Time in Business',
        },
        ctaBox: {
          title1: 'Fund your Business now',
          description1:
            'We are dedicated to helping minority business succeed. We believe access to capital is one of the greatest challenges for minority businesses.',
          btnText: 'START APPLICATION',
        },
        comparisonTable: {
          title1: 'The best capital for',
          title2: ' businesses like yours',
          rowHeader1: 'Accept ITIN?',
          rowHeader2: 'No credit history accepted?',
          rowHeader3: 'Annual interest rate',
          colHeader1: 'Camino Financial',
          colHeader2: 'Merchant Cash Advance',
          colHeader3: 'Lines of Credit',
          valC1R3: '12-25%',
          valC2R2: 'Depends on the lender',
          valC2R3: '40-150%',
          valC3R3: '7-25%',
        },
        splitSection: {
          title: 'Pre-qualify in 5 minutes',
        },

        footer: {
          title1: 'STAY CONNECTED',
          description1:
            'Join a community of over 40,000 people who receive bi-weekly tips on growing your business.',
          signupPlaceholder: 'Enter your email address',
          signup: 'SIGN UP',
          title2: 'MISSION-DRIVEN COMPANY',
          description2:
            'Camino Financial’s mission is to catalyze economic growth within low-to-moderate income communities by empowering small businesses to create new jobs and grow sustainably.',
          link2: {
            label: 'Read Our Story ',
            url: `${process.env.GATSBY_CAMINO_URL}/about-us/0/`,
          },
          title3: 'NAVIGATE',
          sitemap: {
            link1: {
              label: 'Success Stories',
              url: `${process.env.GATSBY_CAMINO_URL}/success-stories/`,
            },
            link2: {
              label: 'Loan Application',
              url: `${process.env.GATSBY_CAMINO_URL}/get-me-a-loan10/`,
            },
            link3: {
              label: 'Business Loans',
              url: `${process.env.GATSBY_CAMINO_URL}/small-business-loans/`,
            },
            link4: {
              label: 'Business Loan Calculator',
              url: `${process.env.GATSBY_CAMINO_URL}/business-loan-calculator/`,
            },
            link5: {
              label: 'Sitemap',
              url: `${process.env.GATSBY_CAMINO_URL}/sitemap/`,
            },
            link6: {
              label: 'Security',
              url: `${process.env.GATSBY_CAMINO_URL}/security/`,
            },
            link7: {label: '', url: ''},
            link8: {
              label: 'Sign In',
              url: `${process.env.GATSBY_CAMINO_URL}/signin`,
            },
            link9: {
              label: 'Reviews',
              url: `${process.env.GATSBY_CAMINO_URL}/reviews/`,
            },
            link10: {
              label: 'About Us',
              url: `${process.env.GATSBY_CAMINO_URL}/about-us/`,
            },
            link11: {
              label: 'Contact Us',
              url: `${process.env.GATSBY_CAMINO_URL}/contact-us/`,
            },
            link12: {
              label: 'Business Resources',
              url: `${process.env.GATSBY_CAMINO_URL}/resources/`,
            },
            link13: {
              label: 'Careers',
              url: 'https://www.linkedin.com/company/camino-financial/jobs/',
            },
            link14: {
              label: 'Tech Blog',
              url: 'https://techblog.caminofinancial.com/',
            },
            link15: {
              label: 'Do Not Sell My Info',
              url:
                'https://privacyportal.onetrust.com/webform/e2cf87e2-04b0-4669-a94d-298d7fa99bbb/c315c398-738e-4cfd-8544-a7905247065a',
            },
          },
          bottomFotter: {
            link1: {
              label: 'Terms of Use',
              url: `${process.env.GATSBY_CAMINO_URL}/terms-of-use/`,
            },
            link2: {
              label: 'Privacy Policy',
              url: `${process.env.GATSBY_CAMINO_URL}/privacy-policy/`,
            },
            link3: {
              label: 'CCPA',
              url: `${process.env.GATSBY_CAMINO_URL}/ccpa-policy/`,
            },
          },
          rightsReserved:
            '2021 by Camino Financial, Inc. All Rights Reserved. Camino Financial is a Licensed Finance Lender & Broker in California under its subsidiary Salas & Company LLC.',
        },

        faq: {
          title: 'Frequently Asked Questions',
          question1: {
            question: '1. How can I get a business loan?',
            answer: '',
          },
          question2: {
            question: '2. What are the requirements?',
            answer: '',
          },
          question3: {
            question:
              '3. What type of business loan does Camino Financial offer?',
            answer: '',
          },
          question4: {
            question: '4. Should I apply for a business loan with CF?',
            answer: '',
          },
          question5: {
            question: '5. Can I get a business loan if I only have an ITIN?',
            answer: '',
          },
          question6: {
            question:
              '6. Can I apply for a business loan if I don’t have a credit history?',
            answer: '',
          },
          question7: {
            question:
              '7. Does the Camino Financial application affect my credit score?',
            answer: '',
          },
          question8: {
            question:
              '8. Do I need collateral to apply for a Camino Financial loan?',
            answer: '',
          },
          question9: {
            question: '9. What are your interest rates?',
            answer: '',
          },
          question10: {
            question: '10. Is the lending process safe?',
            answer: '',
          },
        },
      },
    },
  },
};

export default translations;
